import { LazyLoad } from './modules/lazy-load.min.js';
import { BeeInfo } from './modules/bee-info.min.js';
import { Swipers } from './modules/swipers.min.js';

let appLenis = {};
let swup = null;

const isPhone = window.innerWidth < 768;

// Initialize page
function init() {
  // Initialize Gsap + ScrollTrigger
  // gsap.registerPlugin(ScrollTrigger);

  // Initialize Swup
  swup = new Swup({
    containers: ['#swup', '#header', '#footer'],
    cache: false,
  });

  // Init scroll monitor
  transitionCallback();

  // Set transition callback
  swup.hooks.on('content:replace', transitionCallback);

  // Reload iubenda iframes
  swup.hooks.on('page:view', function() {
    if ($('.iubenda-embed').length > 0) {
      location.reload();
    }
  });
}

const initLenis = () => {
  appLenis = {
    initialized: false,
    _lenis: null,

    init() {
      const wrapper = document.getElementById('wrapper');
      // const frame = document.getElementById('frame');

      this._lenis = new Lenis({
        wrapper: isPhone ? window : wrapper,
        eventsTarget: window,
      });

      const raf = (time) => {
        this._lenis.raf(time);
        requestAnimationFrame(raf);
      };

      requestAnimationFrame(raf);

      // this._lenis = new Lenis({
      //   lerp: 0.085,
      // });

      // this.initEvents();
      this.initialized = true;
    },

    // initEvents() {
    //   this._lenis.on('scroll', ScrollTrigger.update);

    //   gsap.ticker.add((time) => {
    //     this._lenis.raf(time * 1000);
    //   });
    // },

    destroy() {
      this._lenis.stop();
      this._lenis.destroy();
    },
  };

  // setTimeout(() => {
  //   appLenis.init();
  // }, 500);

  appLenis.init();
};

const destroyAnimations = () => {
  const Alltrigger = ScrollTrigger.getAll();
  for (let i = 0; i < Alltrigger.length; i++) {
    Alltrigger[i].kill(true);
  }
};

// Callback called on swup transition
function transitionCallback() {
  // Destroy prvious animations
  // destroyAnimations();

  // Stop lenis to prevent scrolling during transitions
  if (appLenis.initialized) {
    appLenis.destroy();
  }

  // Initialize Lenis
  if (!isPhone) {
    initLenis();
  }

  // Initialize swipers
  new Swipers().init();

  // Reinitialize lazy load
  new LazyLoad().init();

  // Init BeeInfo class to set correct position ref to productHeader
  new BeeInfo();

  // Iubenda cleanup
  iubendaCheck();

  // Hide/Show reCaptcha badge based on page
  if ($('#contact-form').length > 0) {
    $('.grecaptcha-badge').addClass('visible');
  } else {
    $('.grecaptcha-badge').removeClass('visible');
  }
}

function iubendaCheck() {
  // Remove iubenda cookie message
  setTimeout(function() {
    if (_iub.cs.consent.purposes !== undefined) {
      let purposes_iub = Object.keys(_iub.cs.consent.purposes);

      for (let i = 0; i < purposes_iub.length; i++) {
        if (_iub.cs.consent.purposes[purposes_iub[i]] == true) {
          // Hide cookie message for enabled purposes
          $('.content-before-consent-' + purposes_iub[i]).each(function(_, el) {
            el.style.display = 'none';
          });

          // Send events to GTM
          switch (purposes_iub[i]) {
            case '2':
              // Attivatore Tag Manager categoria Interazioni Semplici
              dataLayer.push({
                event: 'iubenda-interazioni-semplici',
              });
              break;
            case '3':
              // Attivatore Tag Manager categoria Esperienza Migliorata
              dataLayer.push({
                event: 'iubenda-esperienza-migliorata',
              });
              break;
            case '4':
              // Attivatore Tag Manager categoria Misurazione
              dataLayer.push({ event: 'iubenda-misurazione' });
              break;
            case '5':
              // Attivatore Tag Manager categoria Pubblicità
              dataLayer.push({ event: 'iubenda-pubblicita' });
              break;
          }
        } else {
          // Show cookie message for disabled purposes
          $('.content-before-consent-' + purposes_iub[i]).each(function(_, el) {
            el.style.display = 'flex';
          });
        }
      }
    } else {
      // Show cookie message for disabled purposes
      $('.content-before-consent').each(function(_, el) {
        el.style.display = 'block';
      });
    }
    _iub.cs.api.activateSnippets();
  }, 500);
}

function removePreloader() {
  // Remove preloader
  $('.preloader').fadeOut(500);
}

// Initialize page
$(window).on('load', function() {
  init();
  removePreloader();
});
